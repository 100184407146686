var render = function () {
  var _vm$$v$investmentData, _vm$$v;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "add-new-investor-form"
  }, [_vm.skip2ndStep ? _c('div', {
    staticClass: "content"
  }, [_c('sygni-loader', {
    attrs: {
      "isLoading": true
    }
  })], 1) : _c('div', {
    staticClass: "content"
  }, [_c('sygni-container-title', {
    staticClass: "mb-3"
  }, [_vm.viewType === 'newInvestor' ? _c('span', [_vm._v("Add new investor")]) : _vm._e(), _vm.viewType === 'editInvestor' ? _c('span', [_vm._v("Edit investor")]) : _vm._e(), _vm.viewType === 'verifyInvestor' ? _c('span', [_vm._v("Authorize your data")]) : _vm._e()]), _c('div', {
    staticClass: "content__title"
  }, [_vm._v(" Detail data ")]), _vm.viewType === 'verifyInvestor' ? [_c('sygni-input', {
    staticClass: "secondary",
    attrs: {
      "label": 'FIRST NAME',
      "validation": _vm.$v.investmentData.firstName
    },
    on: {
      "blur": function blur($event) {
        return _vm.$v.investmentData.firstName.$touch();
      }
    },
    model: {
      value: _vm.investmentData.firstName,
      callback: function callback($$v) {
        _vm.$set(_vm.investmentData, "firstName", $$v);
      },
      expression: "investmentData.firstName"
    }
  }), _c('sygni-input', {
    staticClass: "secondary",
    attrs: {
      "label": 'SURNAME',
      "validation": _vm.$v.investmentData.surname
    },
    on: {
      "blur": function blur($event) {
        return _vm.$v.investmentData.surname.$touch();
      }
    },
    model: {
      value: _vm.investmentData.surname,
      callback: function callback($$v) {
        _vm.$set(_vm.investmentData, "surname", $$v);
      },
      expression: "investmentData.surname"
    }
  })] : _vm._e(), _vm.displayField('title') ? _c('sygni-select', {
    staticClass: "secondary",
    attrs: {
      "options": _vm.investorsSelects.title,
      "label": 'TITLE',
      "placeholder": 'Mr.',
      "validation": _vm.$v.investmentData.details.title
    },
    model: {
      value: _vm.investmentData.details.title,
      callback: function callback($$v) {
        _vm.$set(_vm.investmentData.details, "title", $$v);
      },
      expression: "investmentData.details.title"
    }
  }) : _vm._e(), _vm.displayField('middle_name') ? _c('sygni-input', {
    staticClass: "secondary",
    attrs: {
      "label": 'MIDDLE NAME',
      "validation": _vm.$v.investmentData.details.middleName
    },
    model: {
      value: _vm.investmentData.details.middleName,
      callback: function callback($$v) {
        _vm.$set(_vm.investmentData.details, "middleName", $$v);
      },
      expression: "investmentData.details.middleName"
    }
  }) : _vm._e(), _c('div', [_c('div', {
    staticClass: "d-flex flex-row align-items-end"
  }, [_vm.displayField('birth_date') ? _c('sygni-date-picker', {
    staticClass: "secondary",
    attrs: {
      "label": 'BIRTH DAY',
      "validation": _vm.$v.investmentData.details.birthDate
    },
    model: {
      value: _vm.investmentData.details.birthDate,
      callback: function callback($$v) {
        _vm.$set(_vm.investmentData.details, "birthDate", $$v);
      },
      expression: "investmentData.details.birthDate"
    }
  }) : _vm._e()], 1)]), _vm.displayField('birth_place') ? _c('sygni-input', {
    staticClass: "secondary",
    attrs: {
      "label": 'BIRTH PLACE',
      "validation": _vm.$v.investmentData.details.birthPlace
    },
    model: {
      value: _vm.investmentData.details.birthPlace,
      callback: function callback($$v) {
        _vm.$set(_vm.investmentData.details, "birthPlace", $$v);
      },
      expression: "investmentData.details.birthPlace"
    }
  }) : _vm._e(), _vm.displayField('fathers_name') ? _c('sygni-input', {
    staticClass: "secondary",
    attrs: {
      "label": 'FATHERS NAME',
      "validation": _vm.$v.investmentData.details.fathersName
    },
    model: {
      value: _vm.investmentData.details.fathersName,
      callback: function callback($$v) {
        _vm.$set(_vm.investmentData.details, "fathersName", $$v);
      },
      expression: "investmentData.details.fathersName"
    }
  }) : _vm._e(), _vm.displayField('mothers_name') ? _c('sygni-input', {
    staticClass: "secondary",
    attrs: {
      "label": 'MOTHERS NAME',
      "validation": _vm.$v.investmentData.details.mothersName
    },
    model: {
      value: _vm.investmentData.details.mothersName,
      callback: function callback($$v) {
        _vm.$set(_vm.investmentData.details, "mothersName", $$v);
      },
      expression: "investmentData.details.mothersName"
    }
  }) : _vm._e(), _vm.displayField('phone_number') ? _c('sygni-input', {
    staticClass: "secondary",
    attrs: {
      "label": 'PHONE NO.',
      "validation": _vm.$v.investmentData.details.phone
    },
    model: {
      value: _vm.investmentData.details.phone,
      callback: function callback($$v) {
        _vm.$set(_vm.investmentData.details, "phone", $$v);
      },
      expression: "investmentData.details.phone"
    }
  }) : _vm._e(), _vm.displayField('identification_number') ? _c('sygni-input', {
    staticClass: "secondary",
    attrs: {
      "label": 'IDENTIFICATION NO.',
      "validation": _vm.$v.investmentData.details.identificationNumber
    },
    model: {
      value: _vm.investmentData.details.identificationNumber,
      callback: function callback($$v) {
        _vm.$set(_vm.investmentData.details, "identificationNumber", $$v);
      },
      expression: "investmentData.details.identificationNumber"
    }
  }) : _vm._e(), _vm.displayField('document_type') ? _c('sygni-select', {
    staticClass: "secondary",
    attrs: {
      "options": _vm.investorsSelects.documentType,
      "label": 'DOCUMENT TYPE',
      "validation": _vm.$v.investmentData.details.documentType
    },
    model: {
      value: _vm.investmentData.details.documentType,
      callback: function callback($$v) {
        _vm.$set(_vm.investmentData.details, "documentType", $$v);
      },
      expression: "investmentData.details.documentType"
    }
  }) : _vm._e(), _vm.displayField('document_number') ? _c('sygni-input', {
    staticClass: "secondary",
    attrs: {
      "label": 'DOCUMENT NO.',
      "validation": _vm.$v.investmentData.details.documentNumber
    },
    model: {
      value: _vm.investmentData.details.documentNumber,
      callback: function callback($$v) {
        _vm.$set(_vm.investmentData.details, "documentNumber", $$v);
      },
      expression: "investmentData.details.documentNumber"
    }
  }) : _vm._e(), _c('div', [_c('div', {
    staticClass: "expiry-date"
  }, [_c('div', {
    staticClass: "expiry-date__checkbox"
  }, [_c('sygni-checkbox', {
    staticClass: "secondary",
    attrs: {
      "label": "INDEFINITELY"
    },
    model: {
      value: _vm.investmentData.details.documentExpiryDate.indefinitely,
      callback: function callback($$v) {
        _vm.$set(_vm.investmentData.details.documentExpiryDate, "indefinitely", $$v);
      },
      expression: "investmentData.details.documentExpiryDate.indefinitely"
    }
  })], 1)]), _vm.displayField('document_expiry_date') ? _c('sygni-date-picker', {
    staticClass: "secondary",
    attrs: {
      "label": 'DOCUMENT EXPIRY DATE',
      "disabledDate": _vm.disabledDate,
      "disabled": _vm.isDocumentExpiryFieldDisabled,
      "validation": (_vm$$v$investmentData = _vm.$v.investmentData.details.documentExpiryDate) === null || _vm$$v$investmentData === void 0 ? void 0 : _vm$$v$investmentData.expiryDate
    },
    model: {
      value: _vm.investmentData.details.documentExpiryDate.expiryDate,
      callback: function callback($$v) {
        _vm.$set(_vm.investmentData.details.documentExpiryDate, "expiryDate", $$v);
      },
      expression: "investmentData.details.documentExpiryDate.expiryDate"
    }
  }) : _vm._e()], 1), _vm.displayField('document_country') ? _c('sygni-select', {
    staticClass: "secondary",
    attrs: {
      "options": _vm.globalSelects.countries,
      "label": "DOCUMENT COUNTRY",
      "menu-props": {
        top: true,
        offsetY: true
      },
      "validation": (_vm$$v = _vm.$v) === null || _vm$$v === void 0 ? void 0 : _vm$$v.investmentData.details.documentCountry,
      "searchable": true
    },
    model: {
      value: _vm.investmentData.details.documentCountry,
      callback: function callback($$v) {
        _vm.$set(_vm.investmentData.details, "documentCountry", $$v);
      },
      expression: "investmentData.details.documentCountry"
    }
  }) : _vm._e(), _vm.displayField('country') ? _c('sygni-select', {
    staticClass: "secondary",
    attrs: {
      "options": _vm.globalSelects.countries,
      "label": 'COUNTRY OF TAX RESIDENCE',
      "menu-props": {
        top: true,
        offsetY: true
      },
      "validation": _vm.$v.investmentData.details.country,
      "searchable": true
    },
    model: {
      value: _vm.investmentData.details.country,
      callback: function callback($$v) {
        _vm.$set(_vm.investmentData.details, "country", $$v);
      },
      expression: "investmentData.details.country"
    }
  }) : _vm._e(), _vm.displayField('nationality') ? _c('sygni-select', {
    staticClass: "secondary",
    attrs: {
      "options": _vm.globalSelects.countries,
      "label": 'NATIONALITY',
      "menu-props": {
        top: true,
        offsetY: true
      },
      "validation": _vm.$v.investmentData.details.nationality,
      "searchable": true
    },
    model: {
      value: _vm.investmentData.details.nationality,
      callback: function callback($$v) {
        _vm.$set(_vm.investmentData.details, "nationality", $$v);
      },
      expression: "investmentData.details.nationality"
    }
  }) : _vm._e(), _vm.viewType === 'editInvestor' ? _c('div', {
    staticClass: "add-new-investor-form__propagate"
  }, [_c('sygni-checkbox', {
    staticClass: "secondary propagate",
    attrs: {
      "value": _vm.propagate,
      "removeWatcher": true,
      "label": "PROPAGATE THE DATA"
    },
    on: {
      "changedValue": _vm.setPropagate
    }
  }), _c('p', {
    class: ['add-new-investor-form__note', !_vm.propagate ? 'hidden' : '']
  }, [_c('span', {
    staticClass: "text-danger"
  }, [_vm._v("Important!")]), _vm._v(" Remember that this action will result in changes across all Legal Entities")])], 1) : _vm._e(), _c('sygni-rounded-button', {
    staticClass: "filled gn-primary button secondary",
    on: {
      "click": function click($event) {
        return _vm.goNext();
      }
    }
  }, [_vm._v(_vm._s(_vm.skip3rdStep ? 'Save' : 'Next'))]), _c('router-link', {
    attrs: {
      "to": _vm.backButtonLink
    }
  }, [_c('div', {
    staticClass: "cancel-button"
  }, [_vm._v("Cancel")])])], 2)]);
}
var staticRenderFns = []

export { render, staticRenderFns }