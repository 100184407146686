import {required} from "vuelidate/lib/validators";
import {zipCode} from "@/plugins/vuelidate";

export const addNewInvestorStepTwoValidation = {
    investmentData: {
        details: {
            birthDate: { birthDay: (value: string) => {
                    if(!value) return true;
                    return Date.parse(value) < Date.now();
                }},
            documentExpiryDate: {
                expiryDate: (value: string) => {
                    if (!value) return true;
                    const date = new Date(new Date().toDateString())
                    return Date.parse(value) >= date.setDate(date.getDate());
                }
            }
        },
    }
}

export const verifyNewInvestorStepTwoValidation = {
    investmentData: {
        firstName: {required},
        surname: {required},
        details: {
            title: {required},
            birthDate: {
                wrongDate: (value: string) => {
                    if(!value) return true;
                    return Date.parse(value) < Date.now();
                },
                required
            },
            documentExpiryDate: {
                expiryDate:{
                    wrongDate: (value: string) => {
                        if (!value) return true;
                        const date = new Date(new Date().toDateString())
                        return Date.parse(value) >= date.setDate(date.getDate());
                    }} ,
                required
            },
            fathersName: {required},
            mothersName: {required},
            phone: {required},
            identificationNumber: {required},
            documentType: {required},
            documentNumber: {required},
            country: {required},
        },
    }
}

export const addNewInvestorStepThreeValidation =  () => ({
    investmentData: {
        residentialAddress: {
            zipCode: {
                zipCode: zipCode
            }
        },
        mailingAddress: {
            zipCode: {
                zipCode: zipCode,
            }
        }
    }
});

export const verifyInvestorStepThreeValidation = () => {
    return {
        investmentData: {
            residentialAddress: {
                zipCode: {
                    zipCode: zipCode,
                    required
                },
                city: {required},
                street: {required},
                houseNumber: {required},
                country: {required},
            },
            mailingAddress: {
                zipCode: {
                    zipCode: zipCode,
                    required
                },
                city: {required},
                street: {required},
                houseNumber: {required},
                country: {required},
            }
        }
    }
}
